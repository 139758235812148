import './App.css';
import Header from './Common/Header/Header';
import Auth from './Components/Auth/Auth';
import { Switch, Route } from 'react-router-dom'
import Users from './Components/users/Users';
import { useSelector, useDispatch } from 'react-redux'
import Admin from './Components/admin/Admin';
import Accounts from './Components/Accounts/Accounts';
import Logs from './Components/Logs/Logs';
import { useEffect } from 'react';
import axios from 'axios';
import { usersActions } from './store/user';
import Landing from './Components/Landing/Landing';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'

function App() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        axios.get('/api/admin')
            .then((response) => {
                const data = response.data.data;
                dispatch(usersActions.changeIsLoggedIn(true));
                dispatch(usersActions.setLoggedInHandler(data.role));
            })
            .catch((err) => {
                dispatch(usersActions.changeIsLoggedIn(false))
            })
    }, [dispatch])

    return (
        <div className="App">
            {user.isLoggedIn === true && <Header />}
            <Switch>
                <Route path='/sign-up' exact><Landing /></Route>
                <Route path='/' exact>
                    {user.isLoggedIn === true && <Users />}
                    {user.isLoggedIn === false && <Auth />}
                </Route>
                <Route path='/admins' exact>
                    {user.isLoggedIn === true && <Admin />}
                    {user.isLoggedIn === false && <Auth />}
                </Route>
                <Route path="/accounts">
                    {user.isLoggedIn === true && <Accounts />}
                    {user.isLoggedIn === false && <Auth />}
                </Route>
                <Route path="/logs">
                    {user.isLoggedIn === true && <Logs />}
                    {user.isLoggedIn === false && <Auth />}
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
            </Switch>
        </div>
    );
}

export default App;
